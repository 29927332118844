@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'bootstrap/dist/css/bootstrap.min.css';

@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --main:black;
  --light:lightgrey;
  --gray: rgb(125, 122, 122);
}

.App {
  min-height: 100vh;
}

body {
  font-family: 'Inconsolata', monospace;
  min-height: 100vh;
  position: relative;
}

a:hover {
  color: var(--gray);
}

	/* Layouts */
	
	.grid-centered {
		@apply 
			grid 
			place-items-center
			row-span-5
	}

  .centered {
    @apply
    grid place-items-center h-screen
  }

	.flex-centered {
		@apply
			flex
			justify-center
			items-center
	}

	.horizontal {
		@apply
			flex
			flex-row;
	}

	.vertical {
		@apply
			flex
			flex-col;
	}

  
	/* NavBar */
  .nav-bar-container {
    @apply
      vertical
      justify-evenly
      w-2/5
      ;
  }

  .nav-logo {
    @apply
      w-2/5
  }

  .navbar {
    background-color:white;
    color: var(--main);
    @apply
      text-xl
      font-semibold
  }
  
  .nav-links {
    @apply
      sm:grid
      
  }

  .nav-link {
    @apply
      sm:row-span-1;
  }

  .nav-name:hover {
    color: grey;
  }
  
  .menu {
    background-color: black;
    @apply
      h-screen
      w-screen
      z-10
      fixed
      top-0
      left-0
      vertical
      justify-evenly
      text-center
  }
  
  .hover-nav-small {
    @apply 
      hover:text-gray-700
      hover:font-normal
  }

  /* LaunchPage */
  .logo-video-container {
    @apply
    w-[600px]
    sm:w-[80vw]
  }

  iframe {
    @apply
    pr-0
    pl-0
    mb-[20vh]
    sm:mb-[50vh]
    sm:mt-0
  }

  #iframe-logo {
    width:100%;
  }

  /* FashionStyling */
  .work-containers {
    @apply
    py-6
    sm:py-12
  }

  .work-container {
    @apply
    grid 
    grid-cols-5
    sm:grid-cols-3 
    px-6 
    gap-x-0
    sm:px-0
    sm:gap-8 
  }

  .text-title {
    @apply
    text-base 
    sm:text-lg;
  }

  .text-section {
    @apply
    flex
    col-span-5
    sm:col-span-1
    flex-col
    text-sm 
    pt-6
    sm:pt-0
    sm:pr-0
    sm:text-base
    justify-self-center
    sm:justify-self-auto
  }

  .number {
    @apply
    col-span-1
    pl-0
    sm:pl-8
    sm:col-span-1
  }

  .main-img-container {
    @apply
    col-span-4
    sm:col-span-1
  }
  
  .main-img {
    @apply
    sm:w-11/12
  }

.alt-images {
  align-items: center;
  justify-content: center;
  @apply
  pt-8
  pb-12
  w-[100vw]
  space-x-2
  px-6
  overflow-x-auto
  ;
}

.alt-image {
  @apply
  w-2/5
  sm:w-72
  ;
}

.overflow-images {
  @apply
  pt-8
  pb-12
  w-[100vw]
  space-x-2
  pl-4
  pr-4
  overflow-x-auto
  ;
}

.single-img {
  @apply
  w-4/5
  sm:w-[400px]
;
}

.fashion-assist-container {
  @apply
  grid
  place-content-center
  pb-12
  animate-bounce
}

/* Popeye JP Issue Images */
.main-popeye-img {
  border-width: 2px;
  border-color: green;
}

.popeye-img-1 {
  border-width: 2px;
  border-color: #FF2EB6;
}

.popeye-img-2 {
  border-width: 2px;
  border-color: #025107;
}

.popeye-img-3 {
  border-width: 2px;
  border-color: #FF7712;
}

.popeye-img-4 {
  border-width: 2px;
  border-color: blue;
}

.popeye-img-5 {
  border-width: 2px;
  border-color: lightgreen;
}

.popeye-img-6 {
  border-width: 2px;
  border-color: darkred;
}

/* Rirchardson AW 2022 Images */
.main-richardson-img {
  border-width: 2px;
  border-color: cyan;
}

.richardson-img-2 {
  border-width: 2px;
  border-color: greenyellow;
}

.richardson-img-3 {
  border-width: 2px;
  border-color: rgb(253, 101, 149);
  @apply
  sm:w-[340px]
}

/* Isabella Lovestory Images */
.isabellalovestory-img {
  border-width: 2px;
  border-color: rgb(255, 72, 0);
}

.isabellalovestory-img-2 {
  border-width: 2px;
  border-color: rgb(50, 132, 255);
}

/* Isabella Lovestory x Jiu Jie Decor Images */
.main-isabellalovestoryJJ-img {
  border-width: 2px;
  border-color: #39FF14;
}

.isaballalovestoryJJ-img-2 {
  border-width: 2px;
  border-color: #FF00FF;
}

.isabellalovestoryJJ-img-3 {
  border-width: 2px;
  border-color: rgb(255, 238, 0);
}

/* Caleb Giles for Public Records Images */
.main-caleb-img {
  border-width: 2px;
  border-color: #FF2EB6;
}

.caleb-img-1 {
  border-width: 2px;
  border-color: #FF2EB6;
}

.caleb-img-2 {
  border-width: 2px;
  border-color: blue;
}

.caleb-img-3 {
  border-width: 2px;
  border-color: cyan;
}

.caleb-img-4 {
  border-width: 2px;
  border-color: green;
}

.caleb-image {
  @apply
  h-28
  sm:h-72
}

/* Eartheater for Moma PS1 Warm-Up Image */
.eartheater-img {
  border-width: 2px;
  border-color: green;
}

/* Eartheater for Richardson FW2021 Images */
.main-eartheaterJJ-img {
  border-width: 2px;
  border-color: maroon;
  
}

.eartheaterJJ-img-2 {
  border-width: 2px;
  border-color: rgb(248, 154, 22);
}

/* BOL BOL Draft Night 2019 Images */
.main-bol-img {
  border-width: 2px;
  border-color: #0039BB;
}

.bol-img-2 {
  border-width: 2px;
  border-color: red;
}

.bol-img-3 {
  border-width: 2px;
  border-color: yellow;
}

/* Creative Page */
.creative-title {
  @apply
  text-xs
  sm:text-base
}
.blonded-main-img {
  border-width: 2px;
  border-color:#FF2EB6;
}

.blonded-img-1 {
  border-width: 2px;
  border-color: #FFF002;
}

.blonded-img-2 {
  border-width: 2px;
  border-color: red;
}

.blonded-img-3 {
  border-width: 2px;
  border-color: blue;
}

.blonded-img-4 {
  border-width: 2px;
  border-color: green;
}

.blotter-main-img {
  border-width: 2px;
  border-color: green;
}

/* About/Contact Page */

.about-contact-container {
  background-color: var(--light);
  color: black;
  @apply
  rounded-2xl
  text-center
  w-2/3
  max-h-[80vh]
  overflow-y-auto
  p-4
  border
  border-slate-400
}

.about-title {
  @apply
  font-semibold
}

.about-text {
  @apply
  text-sm
  sm:text-base
}

/* Footer */

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;  
  @apply
  pt-12
  pb-4
}


